import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, h1Mob } from '~styles/global'
import Section from '~components/Section'
import Image from '~components/Image'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import Video from '~components/Video'

const ProjectLine = ({ className, project, i }) => {

	const [hover, setHover] = useState(false)
	const [above, setAbove] = useState(undefined)
	const imageRef = useRef()
	const lineRef = useRef()


	// useEffect(() => {
	// 	if (imageRef.current){

	// 		const imageHeight = imageRef?.current?.offsetHeight
	// 		const elDistTop = (lineRef.current?.getBoundingClientRect()?.bottom + imageHeight) + window?.pageYOffset
	// 		const docHeight = window?.document?.body?.offsetHeight

	// 		if ((elDistTop + 20) > docHeight) setAbove(true)

	// 		else setAbove(false)

	// 	}
	// }, [imageRef.current, lineRef.current])

	const processHover = () => {
		const imageHeight = imageRef?.current?.offsetHeight
		const elDistTop = (lineRef.current?.getBoundingClientRect()?.bottom + imageHeight) + window?.pageYOffset
		const docHeight = window?.document?.body?.offsetHeight
		const bottomOfWindow = window?.pageYOffset + window?.innerHeight

		if (((elDistTop + 20) > docHeight) || ((elDistTop - 40) > bottomOfWindow)) setAbove(true)

		else setAbove(false)

		setHover(true)
	}

	return (
		<Wrap
			className={className}
			onMouseEnter={() => processHover()}
			onMouseLeave={() => setHover(false)}
			linked={project?.linked}
			ref={lineRef}
		>
			<Link to={project?.linked ? resolveLink(project) : null}>
				<Section className='sm'>
					<Title>{project.industry}</Title>
					<Client>{project.title}</Client>
					<Description>{project.subtitle}</Description>
					<Date>{project.datePublished}</Date>
					<ImageRef ref={imageRef} hover={hover} above={above}>
						{(project?.thumbnailVideo?.asset?.url || project?.thumbnailVimeoVideo) ? 
							<HoverVideo src={project?.thumbnailVimeoVideo ?? project?.thumbnailVideo?.asset?.url}/>
							:
							<HoverImage image={project?.thumbnail} transparent/>
						}
					</ImageRef>
				</Section>
			</Link>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
	padding: 4px 0;
	color: ${ props => props.linked ? 'var(--black)' : 'var(--grey)'};
`
const Title = styled.div`
	grid-column: span 2;
	${mobile}{
		display: none;
	}

`
const Client = styled.div`
	grid-column: span 2;
	${mobile}{
		grid-column: span 8;
	}

`
const Description = styled.div`
	grid-column: span 6;
	${mobile}{
		display: none;
	}
`
const Date = styled.div`
	grid-column: span 2;
	justify-self: end;
	${mobile}{
		grid-column: span 4;
	}
`
const ImageRef = styled.div`
	position: absolute;
	right: 103px;
	top: ${ props => props.above ? 'auto' : '100%'};
	bottom: ${ props => props.above ? '100%' : 'auto'};
	width: 26%;
	pointer-events: none;
	opacity: ${ props => props.hover ? '1' : '0'};
	z-index: 1;
	${mobile}{
		display: none;
	}
`
const HoverImage = styled(Image)`
	width: 100%;
`
const HoverVideo = styled(Video)`
	max-width: 100%;
	display: block;
`

ProjectLine.propTypes = {
	className: PropTypes.string,
	project: PropTypes.object,
	i: PropTypes.number,
}

export default ProjectLine