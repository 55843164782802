import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useState } from 'react'
import { useEffect } from 'react'

const FilterButton = ({ className, onClick, active, children }) => {

	const [hovered, setHovered] = useState(false)
	const [dark, setDark] = useState(false)

	useEffect(() => {
		if(active){
			setDark(true)
			setHovered(false)
		}else{
			if(hovered){
				setDark(true)
			}else{
				setDark(false)
			}
		}
	}, [active, hovered])

	return (
		<Wrap 
			className={className} 
			onClick={onClick} 
			active={dark}
			onMouseMove={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{children}
		</Wrap>
	)
}

const Wrap = styled.button`
	border: 1px solid var(--black);
	padding: 5px 16px 5px 20px;
	border-radius: 40px;
	position: relative;
	display: inline-flex;
	justify-content: center;
	background-color: ${ props => props.active ? 'var(--black)' : 'transparent'};
	color: ${ props => props.active ? 'var(--white)' : 'var(--black)'};
	transition: transform 0.25s, background-color 0.25s, color 0.25s;
	margin-right: 10px;
	margin-bottom: 10px;
	:last-of-type{
		margin-right: 0;
	}
	${mobile}{
		margin-bottom: 6px;
    padding: 5px 13px 4px 16px;
    margin-right: 6px;
	}
`

FilterButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	active: PropTypes.bool,
	children: PropTypes.node
}

export default FilterButton